<template>
    <div>
        <div slot="header" >
          <el-date-picker
            v-model="dateTime"
            size="small"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
          <el-input v-model.trim="inputName" style="width:150px;margin-left: 10px;" size="small"  placeholder="请输入查找姓名"></el-input>
          <el-input v-model.trim="inputCureId" style="width:150px;margin-left: 10px;" size="small"  placeholder="请输入治疗号"></el-input>
          <el-button  type="primary" style="margin-left:10px" @click="selData" size="small"  icon="el-icon-search">查找</el-button>
          <el-button size="small" @click="reSetData" type="primary">刷新</el-button>
             <download-excel 
                      style="float:right;margin-bottom: 10px;"
                      class="btn"
                      :before-generate = "deriveData"
                      :fields = "json_fields"
                      :data="SelectionRow">
                      <el-select v-model="selValue" placeholder="请选择导出数据" @change="changeSel(selValue)" size="small" style="width:150px">
                             <el-option :value="1"  label="导出当页"> 导出当页</el-option>
                             <el-option :value="2" label="导出所有">导出所有</el-option>
                             <el-option :value="3" label="导出查询所有">导出查询所有</el-option>
                      </el-select>
                      <el-button type="success" icon="el-icon-document" size="small">excel</el-button>
              </download-excel>         
       </div>
       <el-table 
           border
           ref="table"
           class="tabelBox"
          :data="tableData"
           size="small"
           v-loading="loading"
           highlight-current-row
           row-key="id"
           @selection-change="handleSelectionChange">
             <el-table-column fixed="left" key="0" type="selection">
             </el-table-column>
             <el-table-column prop="id"   sortable label="序号" width="80">
             </el-table-column>
              <el-table-column type="expand" width="1em" >
                 <template slot-scope="props"  >
                   <p>{{props.row.remark}}</p>
                 </template>
              </el-table-column>
              <el-table-column
                label="姓名"
                prop="name"
                width="100"
             >
                <template  slot-scope="scope">  
                  <el-button type="text"  @click="toogleExpand(scope.row)"> {{ scope.row.name }}  </el-button>   
                </template>
             </el-table-column>
             <el-table-column
                prop="admissionNo"
                label="电疗号"
                width="120"
             >
              <template  slot-scope="scope">
                    <el-badge :value="scope.row.count" class="item" size="small">
                        <el-tag size="small">{{ scope.row.admissionNo}}</el-tag> 
                    </el-badge>
              </template>
             </el-table-column>
             <el-table-column  prop="age"  label="年龄" width="50" >
              <template  slot-scope="scope">
                      {{ scope.row.age}}
              </template>
             </el-table-column>
             <el-table-column prop="time" label="日期" width="100" >
              <template  slot-scope="scope">
                      {{ scope.row.startTime.substring(0,10)}}
              </template>
             </el-table-column>
             <el-table-column prop="state" label="状态" width="100" >
              <template  slot-scope="scope">
                    <el-tag size="small" v-if="scope.row.state!==null" >
                       {{ (scope.row.state==0)?'一般':((scope.row.state==1)?'好':'不佳')  }} 
                      </el-tag> 
                     <span v-else> {{ scope.row.state }} </span>   
              </template>
             </el-table-column>
             <el-table-column prop="updatedTime" label="更新时间" width="100" >
              <template  slot-scope="scope">
                      {{ scope.row.updatedTime.substring(0,10)}}
              </template>
             </el-table-column>
             <el-table-column
                prop="PostictalSuppressionIndex"
                label="抑制指数"
                width="110"
             >
              <template  slot-scope="scope">
                {{ scope.row.PostictalSuppressionIndex}}
              </template>
             </el-table-column>
             <el-table-column
                prop="EEGEndpoint"
                label="脑电波终止时间"
                width="120"
             >
              <template  slot-scope="scope">
                {{ scope.row.EEGEndpoint}}
              </template>
             </el-table-column>
             <el-table-column
                prop="%EnergySet"
                label="能量%"
             >
              <template  slot-scope="scope">
                {{ scope.row['%EnergySet'] }}
              </template>
             </el-table-column>
             <el-table-column
                prop="ChargeDelivered"
                label="电量Mc"
             >
              <template  slot-scope="scope">
                {{ scope.row.ChargeDelivered}}
              </template>
             </el-table-column>
             <el-table-column
                prop="Current"
                label="电流A"
             >
              <template  slot-scope="scope">
                {{ scope.row.Current}}
              </template>
             </el-table-column>
             <!-- <el-table-column
                prop="BaseHeartRate"
                label="平时心率"
                width="110"
             >
              <template  slot-scope="scope">
                {{ scope.row.BaseHeartRate}}
              </template>
             </el-table-column>
             <el-table-column
                prop="PeakHeartRate"
                label="发作心率"
                width="110"
             >
              <template  slot-scope="scope">
                {{ scope.row.PeakHeartRate}}
              </template>
             </el-table-column> -->
             <el-table-column
                prop="cureType"
                label="治疗模式"
                width="190"
             >
              <template slot-scope="scope">
                {{ scope.row.cureType }}
              </template>
             </el-table-column>
            <el-table-column
               fixed="right"
               width="100px"
               label="操作">
                <template slot-scope="scope">
                    <el-button  @click="selRow(scope.row,scope.$index)" type="text" size="small" style="margin-right:12px"> 详情 </el-button>
                    <el-popconfirm title="你确定删除该数据吗？" @confirm="deleteRow(scope.$index,tableData)">
                         <el-button slot="reference" type="text" size="small"  > 删除 </el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
       </el-table>
       <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[30,50,100,200]"
          :page-size="30"
          layout="total, sizes, prev, pager, next, jumper"
          style="float: right;"
          :total="total">
       </el-pagination>

       <el-drawer
          :visible.sync="drawer"
          :with-header="false"
          size="60%"
         >
            <el-button  style="float:right;margin: 10px;" @click="saveCheckList(itemList)" type="primary" size="small"> 保存 </el-button>
            <el-descriptions :column="2"  border  size="small"   style="padding:12;margin-top:20px">
              <el-descriptions-item label="文件名">
                {{ selInfo.fileName }}
              </el-descriptions-item>
              <el-descriptions-item label="更新时间">
                {{ selInfo.updatedTime }}
              </el-descriptions-item>
              <el-descriptions-item label="开始时间">
                {{ selInfo.startTime }}
              </el-descriptions-item>
              <el-descriptions-item label="结束时间">
                {{ selInfo.endTime }}
              </el-descriptions-item>
              <el-descriptions-item :key="li" v-for="(list,li) in itemList" :label="list.description+'('+list.unit+')'">
                <el-input v-model="list.content" size="small"></el-input>
              </el-descriptions-item>
              <el-descriptions-item label="状态评估">
                <el-radio v-model="selInfo.state" label="0">一般</el-radio>
                <el-radio v-model="selInfo.state" label="1">好</el-radio>
                <el-radio v-model="selInfo.state" label="2">不佳</el-radio>
              </el-descriptions-item>
              <el-descriptions-item label="特殊备注" >
                <el-input type="textarea" style="width:300px" :rows="4"  v-model="selInfo.remark"></el-input>
              </el-descriptions-item>
        </el-descriptions>
      </el-drawer>
       </div>
</template>
<script>

import { getCureList,delUserInfo,saveUserInfo,saveCureInfo } from '@/api/cureList'

const json_fields = {
        "姓名":'name',
        "电疗号":'admissionNo',
        // "治疗ID":'cureId',
        // "治疗号":"cureNo",
        "年龄":'age',
        "治疗次数":"cureNum",
        "开始时间":"startTime",
        "结束时间":"endTime",
        // {prop:'GMT',label:"时区"},
        "治疗模式":"cureType",
        "能量设定[年龄]":'%EnergySet',
        "电量[mC]":"ChargeDelivered",
        "电流[A]":"Current",
        "时间[Sec]":"StimulusDuration",
        "频率(Hz)":'Frequency',
        "波宽(mSec)":'PulseWidth',
        "动态电阻(Ohm)":'DynamicImpedance',
        "静态电阻(Ohm)":'StaticImpedance',
        "脑电波的发作终止时间(Sec)":'EEGEndpoint',
        "肌电波活动":'EMGActivity',
        "平时心率(b/m)":'BaseHeartRate',
        "发作时的心率(b/m)":"PeakHeartRate",
        "发作脑电波功率峰值的平均值(µV²)":"AverageSeizureEnergyIndex",
        "发作抑制的指数(%)":"PostictalSuppressionIndex",
        "达到最大持续脑电波的功率(µV²)":"MaximumSustainedPower",
        "达到最大持续脑电波功率的时间(Sec)":"TimetoPeakPower",
        "最大脑电波一致性(%)":'MaximumSustainedCoherence',
        "最大脑电波一致性的时间(Sec)":'name24',
        "早期发作振幅(µV)":"EarlyIctalAmplitude",
        "中值振幅(µV)":"MidictalAmplitude",
        "发作后振幅(µV)":'Post-IctalAmplitude',
};
const editName = ['age','%EnergySet','ChargeDelivered','Current','admissionNo','cureNum','BaseHeartRate','PeakHeartRate','EEGEndpoint'];
const dataProps= [
        {prop:'name',label:"姓名"},
        {prop:'age',label:"年龄"},
        {prop:'cureType',label:"治疗模式"},
        {prop:'startTime',label:"开始时间"},
        {prop:'endTime',label:"结束时间"},
        {prop:'admissionNo',label:"住院号"},
        {prop:'fileName',label:"文件名称"},
        {prop:'time',label:"日期"},
        {prop:'state',label:"状态"},
        {prop:'uptime',label:"更新时间"},
        // {prop:'cureId',label:"治疗ID"},
        // {prop:'cureNo',label:"治疗号"},
        // {prop:'cureNum',label:"治疗次数"},
        // {prop:'GMT',label:"时区"},
        {prop:'%EnergySet',label:"能量设定[年龄]"},
        {prop:'ChargeDelivered',label:"电量[mC]"},
        {prop:'Current',label:"电流[A]"},
        // {prop:'StimulusDuration',label:"时间[Sec]"},
        // {prop:'Frequency',label:"频率(Hz)"},
        // {prop:'PulseWidth',label:"波宽(mSec)"},
        // {prop:'DynamicImpedance',label:"动态电阻(Ohm)"},
        // {prop:'StaticImpedance',label:"静态电阻(Ohm)"},
        {prop:'EEGEndpoint',label:"脑电波的发作终止时间(Sec)"},
        // {prop:'EMGActivity',label:"肌电波活动"},
        // {prop:'BaseHeartRate',label:"平时心率(b/m)"},
        // {prop:'PeakHeartRate',label:"发作时的心率(b/m)"},
        // {prop:'AverageSeizureEnergyIndex',label:"发作脑电波功率峰值的平均值(µV²)"},
        {prop:'PostictalSuppressionIndex',label:"发作抑制的指数(%)"},
        // {prop:'MaximumSustainedPower',label:"达到最大持续脑电波的功率(µV²)"},
        // {prop:'TimetoPeakPower',label:"达到最大持续脑电波功率的时间(Sec)"},
        // {prop:'MaximumSustainedCoherence',label:"最大脑电波一致性(%)"},
        // {prop:'name24',label:"最大脑电波一致性的时间(Sec)"},
        // {prop:'EarlyIctalAmplitude',label:"早期发作振幅(µV)"},
        // {prop:'MidictalAmplitude',label:"中值振幅(µV)"},
        // {prop:'Post-IctalAmplitude',label:"发作后振幅(µV)"},
      ];
  export default {
    name:'DeriveExcel',
    data(){
        return {
            dateTime:null,
            inputName:null,
            json_fields,
            dataProps,
            editName,
            tableData: [],
            SelectionRow:[],
            itemData:{},
            total:0,
            pageNo:1,
            pageSize:30,
            drawer: false,
            itemList:[],
            setTitle:'',
            visible:false,
            search:'',
            loading:false,
            inputCureId:null,
            textarea:'',
            selValue:'',
            selInfo:{},
            getData:[],
            selDerty:false
        }
    },
    methods:{ 
      toogleExpand(row) {
          let $table = this.$refs.table;
          ;$table.toggleRowExpansion(row);
      }, 
     async changeSel(val){
       this.getData = [];
        switch (val) {
          case 1:
            this.SelectionRow =[...this.tableData];
            break;
            case 2:  
            case 3:
               let opt = null;
              if(val === 3){
                if(!this.selDerty) {
                 this.$message.error("没有条件查找的数据");
                 this.SelectionRow = [];
                  return;
                }
                opt = {
                  startDate:(this.dateTime!==null)?this.$moment(this.dateTime[0]).format("YYYY-MM-DD HH:mm:SS"):null,
                  endDate:(this.dateTime!==null)?this.$moment(this.dateTime[1]).format("YYYY-MM-DD HH:mm:SS"):null,
                  name:this.inputName,
                  admissionNo : this.inputCureId
                }
              }else{
                opt = null;
              }
              if(this.total<this.pageSize){
                this.SelectionRow =[...this.tableData];
              }else{
                await  this.getList(opt,val,true);
                this.SelectionRow =[...this.getData];
              }
            break;
          default:
            break;
        }
      },
     async getList(opt,idx,isexcel){
      this.loading = true;
      let res = {};
       if(opt===null){
         res = await getCureList();
       }else{
         res = await getCureList(opt);
       }
        if(res.code === 200){
          this.loading = false;
            if(opt!==null){
               this.tableData = this.computedData(res.rows,isexcel);
               if(idx === 3 && res.total>this.pageSize){
                 this.getData = this.computedData(res.rows,isexcel);
               }
            }else{
                this.getData = this.computedData(res.rows,isexcel);
            }
            this.total = res.total;   
         }else{
            this.$message.error(res.msg);
         }
      },
      computedData(data,isexcel){
        const dataArr = [];
          const dataList = {};
         for(const i in data){
          const item = data[i];
          const list = item.userCureItemsDTOList;
          const dtd =  item.userCureListDTO;
          delete  item.userCureItemsDTOList;
          delete  item.userCureListDTO; 
             if(dataList[i]){
               dataList[i] = item
             }else{
              dataList[i] = {} = item;
              dataList[i].cureType = (dtd)?dtd.contents:null;
              dataList[i].remark =(dtd)?dtd.remark:null;
              dataList[i].state =(dtd)? dtd.state:null;
              this.getKeyName(dataList[i],list,isexcel);
              }
              const id = item.id;
              this.itemData[id] = list;
              dataArr.push(dataList[i])
         }
         return dataArr; 
      },
     async deleteRow(index,rows) {
        const info = rows[index];
        const res = await delUserInfo(info.id);
        if(res.code === 200 ){
          rows.splice(index, 1);
          this.total = this.total -1;
          this.$message.success("删除成功");
        }else{
          this.$message.error("删除有误");
        }
      },
      handleSelectionChange(row){
        this.SelectionRow = row;
      //  console.log(this.SelectionRow);
      },
      getKeyName(data,list,isexcel){
        function forList(list){
          for(const li in list){
                    const itm = list[li];
                    const key = itm.name;
                    const KeyName = key.replace(/\s*/g,""); 
                    data[KeyName] = itm.content;
            }
        }
        if(isexcel){
           if(list){
            forList(list);
           }
        }else{
          if(list){
            forList(list);
         }else{
          data["%EnergySet"] = "";
          data["ChargeDelivered"] = "";
          data["Current"] = "";
          data["BaseHeartRate"] = "";
          data["PeakHeartRate"] = "";
          data["PostictalSuppressionIndex"] = ""
         }
        }
      },
      // -----查找数据
      async selData(){
        if(this.dateTime == null && this.inputName == undefined && this.inputCureId == undefined){
            this.$message.error('请先输入查询信息');
            return;
        }
        const opt = {
          pageNo: 1,
          pageSize:30,
          oderBy:'startTime'
        }
        if(this.dateTime!==null &&  this.dateTime.length>0){
          opt.startDate= this.$moment(this.dateTime[0]).format("YYYY-MM-DD HH:mm:SS");
          opt.endDate = this.$moment(this.dateTime[1]).format("YYYY-MM-DD HH:mm:SS");
        }
        if(this.inputName){
          opt.name = this.inputName;
        }
        if(this.inputCureId){
          opt.admissionNo = this.inputCureId;
        }
         this.getList(opt,3);
         this.selDerty = true;
      },
      // 导出数据
      deriveData(){
        console.log(this.SelectionRow);
        if(this.SelectionRow.length===0){
            this.$message.error('请选择需要导出的数据');
            return ;
        }
      },

      handleSizeChange(val){   
        this.pageSize = val; 
        this.getSelOption();
      },
      handleCurrentChange(val){
        this.pageNo = val;
        this.getSelOption()
      },

      getSelOption(){
        const opt = {
          pageSize:this.pageSize,
          pageNo:this.pageNo,
          oderBy:'startTime'
        }
        if(this.inputName!==null){
          opt.name = this.inputName;
        }
        if(this.inputCureId!==null){
          opt.admissionNo = this.inputCureId;
        }
        this.getList(opt,1);
      },
      selRow(row,index){
          this.selInfo = row;
          this.selInfo.state = (this.selInfo.state == null)?null:String(this.selInfo.state);
          const id = row.id;
          this.saveRows = {row,index};
          this.itemList = this.itemData[id];
          if(this.itemList.length>0){
            this.drawer = true;
          }else{
            this.$message.error("没有检查详情");
          }
      },

      async upSaveData(rows){
          const list = rows;
                list.userCureId = rows.id;
          const res = await saveUserInfo(list);
              if(res.code == 200){
                this.$message.success("更新成功");
              }else{
              this.$message.error("更新有误");
              }      
      },
     async saveCheckList(list){
      const opt = {
         state: this.selInfo.state,
         remark: this.selInfo.remark,
         userCureItemsDTOList:list
      }
        const res = await saveCureInfo(opt);
        if(res.code == 200){
          this.drawer =false;
          const item = this.saveRows.row;
          item.state =(item.state == 'null')?null: Number(item.state);
          const idx = this.saveRows.index;
          this.getKeyName(item,list);
          this.tableData[idx] = item;
          this.$message.success("编辑成功");
        }else{
          this.$message.error("编辑有误");
        }
      },
      reSetData(){
        this.inputCureId = null;
        this.inputName = null;
        this.dateTime = null;
        const opt = {
          pageNo:this.pageNo,
          pageSize:this.pageSize ,
          oderBy:'startTime'
        }
       this.getList(opt,1);
      }
      
    },
    created(){
      // const admissionNo = this.$route.params.admissionNo;
      // console.log(admissionNo);
      const info = this.$route.query;
      if(info){
        this.inputCureId = info.admissionNo;
        this.getSelOption();
      }else{
        this.reSetData();
      }
     
    }
  }
</script>
<style>
.item {
  margin-top: 10px;
}
.el-table .cell .el-table__expand-icon{
  display: none;
}
.expandBox{
  /* display: flex;
  justify-content: center; */
  width: 98%;
  height: 200px;
  /* border:1px saddlebrown solid; */
  text-align: center;
  margin: auto;
}
</style>



